import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { getCookie, setCookie } from '@/ducks/cookies';
import { selectFilters } from '@/ducks/filters/selectors';
import { FILTER_KEY, type FiltersGetCookie, type FiltersKey } from '@/ducks/filters/types';
import { type AppDispatch, type AppGetState, useAppDispatch } from '@/store';

import { config } from './config';
import { actions } from './reducer';
import { getFilterValues, getInvalidFiltersSearchParamsKeys, getSearchParamsKeys } from './utils';

export const useRouterTranslator = () => {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch(updateValues({ getCookie, searchParams }));
  }, [dispatch, pathname, searchParams]);
};

export const useFiltersValuesInit = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch(filtersValuesInit({ searchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const updateValues =
  ({ getCookie, searchParams }: { getCookie: FiltersGetCookie; searchParams: URLSearchParams }) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(actions.updateFilterValuesState(getFilterValues({ getCookie, searchParams, state: getState() })));
  };

const filtersValuesInit =
  ({ searchParams }: { searchParams: URLSearchParams }) =>
  (_: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const invalidSearchParamsKeys = getInvalidFiltersSearchParamsKeys({ getCookie, searchParams, state });
    const params = new URLSearchParams(window.location.search);

    if (invalidSearchParamsKeys.length > 0) {
      invalidSearchParamsKeys.forEach((key) => params.delete(key));
      const qs = params.toString();
      history.replaceState(null, '', `${window.location.pathname}${qs ? `?${qs}` : ''}`);
    }

    const values = selectFilters(state);
    (Object.keys(values) as FiltersKey[]).forEach((key) => {
      config[key]?.onValueInit?.({ getCookie, searchParams: params, setCookie, state, value: values[key] });
    });
  };

export const useAnyAdvancedFilterApplied = () => {
  const searchParams = useSearchParams();

  const advancedFilters = [
    FILTER_KEY.accessible,
    FILTER_KEY.metaType,
    FILTER_KEY.durations,
    FILTER_KEY.homePorts,
    FILTER_KEY.ships,
    FILTER_KEY.cabins,
    FILTER_KEY.sailors,
    FILTER_KEY.weekend,
    FILTER_KEY.priceMax,
    FILTER_KEY.priceMin,
    FILTER_KEY.priceType,
    FILTER_KEY.voyageIds,
  ].reduce<string[]>((keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)], []);

  return advancedFilters.some((key) => searchParams.has(key));
};
