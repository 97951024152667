'use client';

import { ApolloLink, HttpLink } from '@apollo/client';
import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';

import { env } from '@/environment';
import { getIsProdBuild, isProdEnv } from '@/helpers/util/env';

const apolloUri = env.GRAPHQL_URL;

export const makeSSRClient = () => {
  const httpLink = new HttpLink({
    fetchOptions: { next: { revalidate: +env.NEXT_CACHE_TIME } },

    uri: apolloUri,
  });

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({
      typePolicies: {
        Lookup: {
          keyFields: [],
        },
        Settings: {
          keyFields: [],
        },
        Startup: {
          keyFields: [],
        },
        Voyagedata: {
          keyFields: [],
        },
      },
    }),
    connectToDevTools: !isProdEnv() || !getIsProdBuild(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            // in a SSR environment, if you use multipart features like
            // @defer, you need to decide how to handle these.
            // This strips all interfaces with a `@defer` directive from your queries.
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
  });
};
