import { createSelector } from '@reduxjs/toolkit';

import { selectMerchandisingBannerDetails } from '@/ducks/promoBanners/selectors';
import { useAppSelector } from '@/store';

const selectColorMap = createSelector(selectMerchandisingBannerDetails, (bannerInfo) => {
  const isColorsEmpty = !bannerInfo?.colorSwatch1;
  if (isColorsEmpty) return;

  const colorMap = Object.entries(bannerInfo).reduce((acc, [key, value]) => {
    const colorString = key.split('colorSwatch');
    const colorIndex = colorString?.[1];
    if (!colorIndex) {
      return acc;
    }
    return acc + `--promo-color-${colorIndex}: ${value};`;
  }, '');

  return `:root {${colorMap}}`;
});

export const ApplyPromoColors = () => {
  const colorMap = useAppSelector(selectColorMap);
  if (colorMap) {
    return <style>{colorMap}</style>;
  }
};
