import { useEffect, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';

import { allFitters } from '@/components/PromoBanner/Place/helpers/fitters';
import { promoBannerPolicies } from '@/ducks/promoBanners/apolloPolicies';
import { FETCH_PROMO_BANNERS_V2, type GQLPromoBannersApiAnswer } from '@/helpers/api/graphql/queries';
import { apolloErrorToJson } from '@/helpers/apollo/errorsToJson';
import makeApolloClient from '@/helpers/apollo/makeApolloClient';
import { useAppDispatch } from '@/store';

import { setAll } from './slice';

export type TUsePromoBannerUpdaterArgs = {
  beforeStart?: number;
  isEnabled?: boolean;
  repeatInterval: number;
  token: string;
};

const usePromoBannerUpdater = ({ beforeStart, isEnabled, repeatInterval, token }: TUsePromoBannerUpdaterArgs) => {
  const dispatch = useAppDispatch();
  const apolloRef = useRef<ReturnType<typeof makeApolloClient>>();
  const isMountedRef = useRef<boolean>();
  const delayRef = useRef<NodeJS.Timeout>();

  if (!apolloRef.current) apolloRef.current = makeApolloClient({ typePolicies: promoBannerPolicies }, true);

  const [, { data: answer, error: err, startPolling, stopPolling }] = useLazyQuery<GQLPromoBannersApiAnswer>(
    FETCH_PROMO_BANNERS_V2,
    {
      client: apolloRef.current,
      context: {
        fetchOptions: { cache: 'no-store' },
        headers: { Authorization: token },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      ssr: false,
    },
  );

  const dropDelay = () => {
    if (delayRef.current) clearTimeout(delayRef.current);
  };

  const start = () => {
    dropDelay();
    delayRef.current = setTimeout(
      () => {
        dropDelay();
        startPolling(1000 * repeatInterval);
      },
      isMountedRef.current ? 0 : 1000 * (beforeStart ?? repeatInterval),
    );
  };

  const stop = () => {
    dropDelay();
    stopPolling();
  };

  useEffect(() => {
    if (isEnabled) start();
    else stop();
  }, [isEnabled]);

  useEffect(() => {
    isMountedRef.current = true;
    return stop;
  }, []);

  useEffect(() => {
    if (answer) {
      const result = { data: allFitters(answer?.promoBannersV2) || [], error: undefined, isLoaded: true };
      dispatch(setAll(result));
    }
  }, [answer]);

  useEffect(() => {
    if (err) {
      const error = apolloErrorToJson(err);
      if (error) dispatch(setAll({ data: [], error }));
    }
  }, [err]);
};

export default usePromoBannerUpdater;
