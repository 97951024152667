import type {
  GQLBillboardBanner,
  GQLCampaignsPromoBanners,
  GQLDoubleBanner,
  GQLFeaturetteBanner,
  GQLLeaderboardBanner,
  GQLMarqueeBanner,
  GQLMerchandisingBanner,
} from '@/helpers/api/graphql/queries';
import type { TOptional } from '@/types/common';

import { PromoBannerType, type TPromoBannerAny } from '@/ducks/promoBanners/types';

import gatherCTA from './gatherCTA';
import gatherEyebrow from './gatherEyebrow';
import gatherHalf from './gatherHalf';
import { gatherImageSet, gatherVideoSet } from './gatherMediaSet';
import gatherPerks from './gatherPerks';

// TODO: It is highly desirable to transfer these data transformations to the BFF side

export const billboardFitter = (data: GQLCampaignsPromoBanners) =>
  gatherEyebrow(gatherCTA(gatherImageSet(data as GQLBillboardBanner)));

export const doubleFitter = (data: GQLCampaignsPromoBanners) => gatherHalf(data as GQLDoubleBanner);

export const featuretteFitter = (data: GQLCampaignsPromoBanners) =>
  gatherEyebrow(gatherCTA(gatherVideoSet(gatherImageSet(data as GQLFeaturetteBanner))));

export const leaderboardFitter = (data: GQLCampaignsPromoBanners) =>
  gatherPerks(gatherCTA(gatherImageSet(data as GQLLeaderboardBanner)));

export const marqueeFitter = (data: GQLCampaignsPromoBanners) => gatherEyebrow(data as GQLMarqueeBanner);

export const merchandisingFitter = (data: GQLCampaignsPromoBanners) => gatherPerks(data as GQLMerchandisingBanner);

export const FITTERS = {
  [PromoBannerType.billboard]: billboardFitter,
  [PromoBannerType.double]: doubleFitter,
  [PromoBannerType.featurette]: featuretteFitter,
  [PromoBannerType.leaderboard]: leaderboardFitter,
  [PromoBannerType.marquee]: marqueeFitter,
  [PromoBannerType.merchandising]: merchandisingFitter,
};

export const allFitters = (items: TOptional<GQLCampaignsPromoBanners[]>): TOptional<TPromoBannerAny[]> =>
  items?.map((data: GQLCampaignsPromoBanners) => {
    const fitter = FITTERS[data.type as unknown as keyof typeof FITTERS];
    return (fitter ? fitter(data) : data) as TPromoBannerAny;
  });
