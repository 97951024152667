import type { AppDispatch, AppGetState } from '@/store';

import { FILTER_KEY } from '@/ducks/filters/types';
import { clearMultiCurrencies } from '@/ducks/filtersOptions/helpers';
import { selectAgentBookingChannel } from '@/ducks/fm/selectors';
import { removeAgencyData } from '@/ducks/fm/setters';
import { agentDataClear } from '@/ducks/fm/slice';
import { routes } from '@/ducks/routes';

export const resetFmFlow = (additionalParams?: string[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const canRemoveBookingChannel =
    selectAgentBookingChannel(state) === new URLSearchParams(window.location.search).get('bookingChannel');

  removeAgencyData();
  dispatch(clearMultiCurrencies());
  dispatch(agentDataClear());

  await routes.searchWithout([
    ...(additionalParams || []),
    FILTER_KEY.currencyCode,
    'agencyId',
    'agentId',
    ...(canRemoveBookingChannel ? ['bookingChannel'] : []),
  ]);
};
