import type { AppDispatch } from '@/store';

import { checkAccessKeyAndSwapToPromoCodeAction } from '@/ducks/accessKeys';
import { checkCelebrationsReferralCodeAction } from '@/ducks/programs/celebrations/actions';
import { checkReferralCodeAction } from '@/ducks/programs/mgm/actions';

export const checkAllProgramKeys = () => (dispatch: AppDispatch) =>
  Promise.allSettled([
    //@ts-ignore
    dispatch(checkAccessKeyAndSwapToPromoCodeAction()),
    //@ts-ignore
    dispatch(checkReferralCodeAction()),
    //@ts-ignore
    dispatch(checkCelebrationsReferralCodeAction()),
  ]);
