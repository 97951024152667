import {
  ApolloClient,
  InMemoryCache,
  type InMemoryCacheConfig,
  type NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';

import { env } from '@/environment';
import { type TOptional } from '@/types/common';

const makeApolloClient = (
  cacheOptions?: TOptional<InMemoryCacheConfig>,
  isClientOnly?: boolean,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: createHttpLink({
      credentials: 'same-origin',
      uri: `${env.GRAPHQL_URL}`,
    }),
    ssrMode: !isClientOnly,
  });

export default makeApolloClient;
