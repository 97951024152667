import type { TPromoBannerHalf } from '@/ducks/promoBanners/types';
import type { TImage, TNullable, TNullableRecord } from '@/infra/types/common';

import { makeMediaSet } from './gatherMediaSet';

export type THalfOneContainer = {
  contentAlignBannerOne: TNullable<string>;
  ctaTargetBannerOne: TNullable<string>;
  image375BannerOne: TNullableRecord<TImage>;
  image768BannerOne: TNullableRecord<TImage>;
  image992BannerOne: TNullableRecord<TImage>;
  image1200BannerOne: TNullableRecord<TImage>;
  image1600BannerOne: TNullableRecord<TImage>;
  titleBannerOne: TNullable<string>;
};

export type THalfTwoContainer = {
  contentAlignBannerTwo: TNullable<string>;
  ctaTargetBannerTwo: TNullable<string>;
  image375BannerTwo: TNullableRecord<TImage>;
  image768BannerTwo: TNullableRecord<TImage>;
  image992BannerTwo: TNullableRecord<TImage>;
  image1200BannerTwo: TNullableRecord<TImage>;
  image1600BannerTwo: TNullableRecord<TImage>;
  titleBannerTwo: TNullable<string>;
};

export type THalfContainer = THalfOneContainer & THalfTwoContainer;

export type TGatherHalf<D extends THalfContainer> = {
  one: TPromoBannerHalf;
  two: TPromoBannerHalf;
} & Omit<D, keyof THalfContainer>;

export const makeHalf = (
  contentAlign: TNullable<string>,
  ctaTarget: TNullable<string>,
  image375: TNullableRecord<TImage>,
  image768: TNullableRecord<TImage>,
  image992: TNullableRecord<TImage>,
  image1200: TNullableRecord<TImage>,
  image1600: TNullableRecord<TImage>,
  title: TNullable<string>,
): TPromoBannerHalf => ({
  contentAlign: contentAlign || undefined,
  cta: ctaTarget ? { url: ctaTarget! } : undefined,
  imageSet: makeMediaSet({ image375, image768, image992, image1200, image1600 }),
  title: title || undefined,
});

const gatherHalf = <D extends THalfContainer>(data: D): TGatherHalf<D> => {
  const {
    contentAlignBannerOne,
    contentAlignBannerTwo,
    ctaTargetBannerOne,
    ctaTargetBannerTwo,
    image375BannerOne,
    image375BannerTwo,
    image768BannerOne,
    image768BannerTwo,
    image992BannerOne,
    image992BannerTwo,
    image1200BannerOne,
    image1200BannerTwo,
    image1600BannerOne,
    image1600BannerTwo,
    titleBannerOne,
    titleBannerTwo,
    ...restProps
  } = data || {};
  return {
    ...restProps,
    one: makeHalf(
      contentAlignBannerOne,
      ctaTargetBannerOne,
      image375BannerOne,
      image768BannerOne,
      image992BannerOne,
      image1200BannerOne,
      image1600BannerOne,
      titleBannerOne,
    ),
    two: makeHalf(
      contentAlignBannerTwo,
      ctaTargetBannerTwo,
      image375BannerTwo,
      image768BannerTwo,
      image992BannerTwo,
      image1200BannerTwo,
      image1600BannerTwo,
      titleBannerTwo,
    ),
  };
};

export default gatherHalf;
