import type { CookieSetOptions } from 'universal-cookie';

import type { TCurrencyCode } from '@/infra/types/common';
import type { TAgencyAgentResponse } from '@/infra/types/fmlink/agent';

import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { removeCookie, setCookie } from '@/ducks/cookies';

import { AGENCY_COOKIE, AGENCY_CURRENCY_COOKIE, AGENT_COOKIE } from './constants';

const cookieOptions: CookieSetOptions = { expires: 0, path: '/', sameSite: 'strict' };

export const setAgencyData = (agentData: TAgencyAgentResponse) => {
  if (agentData) {
    setCookie(AGENT_COOKIE, agentData.agentDetails, { ...cookieOptions });
    setCookie(AGENCY_COOKIE, agentData.agencyDetails, { ...cookieOptions });
    setCookie(AGENCY_CURRENCY_COOKIE, agentData.agencyCurrencyCode, { ...cookieOptions });
  }
};

export const removeAgencyData = () => {
  removeCookie(CURRENCY_CODE_COOKIE, cookieOptions);
  removeCookie(AGENT_COOKIE, cookieOptions);
  removeCookie(AGENCY_COOKIE, cookieOptions);
  removeCookie(AGENCY_CURRENCY_COOKIE, cookieOptions);
};

export const setAgencyCurrencyCode = (currencyCode: TCurrencyCode) => {
  if (currencyCode) {
    setCookie(AGENCY_CURRENCY_COOKIE, currencyCode, cookieOptions);
  }
};

export const removeAgencyCurrencyCode = () => {
  removeCookie(AGENCY_CURRENCY_COOKIE, cookieOptions);
};
