import type { AppDispatch, RootState } from '@/store';

import { clearLoggedInSessionData } from '@/actions/accounts/dashboard';
import { SET_TOKEN_SUCCESS } from '@/constants/actionTypes';
import { getToken } from '@/ducks/auth/selectors';

import { routes } from '../routes';

export const setToken = (tokenType: string, tokenValue: string) => (dispatch: AppDispatch) => {
  dispatch({
    tokenType,
    tokenValue,
    type: SET_TOKEN_SUCCESS,
  });
};

// HTTP 400 error handler
export const resetUserToken = (userId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    if (userId) {
      const userToken = getToken('BearerUser')(getState());
      const pageUrl = window.location.pathname;
      if (userToken) {
        dispatch(clearLoggedInSessionData());
        dispatch(setToken('BearerUser', ''));
      }
      if (pageUrl.includes(routes.accounts.path)) {
        routes.accounts.signin.go();
      }
    }
  } catch (err) {
    const userToken = getToken('BearerUser')(getState());
    if (userToken) {
      dispatch(clearLoggedInSessionData());
      dispatch(setToken('BearerUser', ''));
    }
  } finally {
    routes.accounts.signin.go();
  }
};
